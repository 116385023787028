.site-btn {
  position: relative;
  background: #074e7a;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  height: 64px;
  color: #fff;
  font-family: Roboto;
  border: none;
  font-size: 16px;
  padding: 0 20px;

  &.yellow {
    background: linear-gradient(180deg, #fcc538 0%, #eda311 100%);
    color: #333333;
  }
  &.btn-sm {
    height: 30px;
    font-size: 14px;
  }
  &:active {
    opacity: 0.7;
  }

  .count {
    position: absolute;
    right: -16px;
    top: -16px;
    z-index: 1;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    background: #f04d23;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
