main .right-side .anamnez-detail h1 {
  margin-top: 48px;
  margin-bottom: 44px;
}

.btn-row {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.anamnez-list {
  display: flex;
  justify-content: space-between;
  padding: 0 60px;

  .anamnez-block {
    width: calc(50% - 15px);
    height: calc(100vh - 85px - 34px - 92px - 94px - 30px);
    position: relative;

    &.hidden .ScrollbarsCustom {
      opacity: 0.5;
    }
    &.hidden .ScrollbarsCustom::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 99;
    }

    .scroller {
      height: 100%;
    }
    .ScrollbarsCustom-Content {
      height: 100%;
    }
    .anamnez-block-inner {
      flex: 1 0 100%;
      height: 100%;
    }

    .ScrollbarsCustom-Wrapper {
      right: 46px !important;
    }
    .ScrollbarsCustom-TrackY {
      background: #fff !important;
      border-radius: 5px !important;
      top: 0 !important;
      height: 100% !important;
    }
    .ScrollbarsCustom-ThumbY {
      background: #fbc437 !important;
      border-radius: 5px !important;
    }
  }
}

.anamnez-result {
  width: calc(50% - 15px);
  height: calc(100vh - 85px - 34px - 92px);
  margin: 0 auto;
}

.anamnez-block-inner {
  form {
    height: 100%;
  }
  p {
    color: #fff;
    margin-bottom: 15px;
  }
}

.anamnez-card {
  background-color: #67b3e2;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 13px;

  &.anamnez-cardSmall {
    padding: 0;
    margin-bottom: 5px;
  }

  .anamnez-titleRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 17px;

    &.anamnez-titleRowSmall {
      margin-bottom: 7px;
    }
  }
  .anamnez-cardText {
    color: #fff;
    font-size: 15px;
    line-height: 30px;

    &.anamnez-cardTextSmall {
      font-size: 12px;
      line-height: 16px;
    }
    ul {
      padding: 0;
      padding-left: 18px;
      margin: 0;
    }
  }
  .anamnez-cardTitle {
    font-size: 18px;

    &.anamnez-cardTitleSmall {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
