.user-block{
    display: block;
    margin-bottom: 20px;
    background: #074E7A;
    border-radius: 5px;
    padding: 20px;
    position: relative;
    margin-right: 16px;
    margin-top: 16px;

    .title{font-size: 18px; line-height: 21px; color: #fff; margin-bottom: 18px;}
    .text{font-size: 15px; line-height: 30px; color: #FFF;}
    
    .user-chats{position: absolute; width: 32px; height: 32px; border-radius: 32px; background: #F04D23; display: flex; justify-content:center; align-items: center; color: #fff; right: -16px; top: -16px;}
}

