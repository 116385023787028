.visit-detail {
  height: calc(100vh - 140px);
  margin: 0 auto;

  h1 {
    margin: 48px 0 44px !important;
  }

  .ScrollbarsCustom-Wrapper {
    right: 46px !important;
  }
  .ScrollbarsCustom-TrackY {
    background: #fff !important;
    border-radius: 5px !important;
    top: 0 !important;
    height: 100% !important;
  }
  .ScrollbarsCustom-ThumbY {
    background: #fbc437 !important;
    border-radius: 5px !important;
  }
}
.visit-block-inner {
  display: flex;
  gap: 50px;
  padding: 0 54px 30px;

  .buttons-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}

.visit-block-left,
.visit-block-right {
  flex: 1;
}
.visit-block {
  padding: 23px 27px;
  background-color: #67b3e2;
  border-radius: 5px;
  margin-bottom: 23px;

  p {
    color: #fff;
  }
}

.field-title {
  font-size: 23px;
  line-height: 27px;
  color: #ffffff;
  margin-bottom: 30px;
  display: block;

  &.small {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.comment-list {
  .comment-item {
    display: block;
    padding: 0;
    margin-bottom: 37px;
  }
  .comment-title {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 17px;
    color: #ffffff;
  }
  .comment-user {
  }
  .comment-date {
    white-space: nowrap;
  }
  .comment-text {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
  }
  .comment-file {
    font-size: 16px;
    font-weight: 300;
    margin-top: 10px;
    line-height: 19px;
    color: #ffffff;

    a {
      color: inherit;
    }
    span {
      font-weight: 400;
    }
  }
}

.chat-container {
  .chat-messages {
    overflow-y: auto;
    height: 500px;
    padding: 35px;
    background: #67b3e2;
    border-radius: 5px 5px 0 0;
    display: flex;
    flex-direction: column;
  }
  .chat-messages .message {
    padding: 10px 25px;
    background: #074e7a;
    border-radius: 5px;
    color: #fff;
    margin-bottom: 15px;
    align-self: flex-start;
  }
  .chat-messages .message.my {
    background: #fff;
    color: #333;
    align-self: flex-end;
  }
  .chat-messages .message:first-child {
    margin-top: auto;
  }
  .chat-messages .message:last-child {
    margin-bottom: 0;
  }
  .chat-input-inner {
    height: 114px;
    background: #146597;
    border-radius: 0px 0px 5px 5px;
    display: flex;
    align-items: center;
    padding: 0 35px;
  }
  .chat-input {
    position: relative;
    width: 100%;
  }
  .chat-input input {
    width: 100%;
    height: 64px;
    border: none;
    background: #ffffff;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.12);
    padding-left: 28px;
    padding-right: 76px;
    border-radius: 5px;
  }
  .chat-input button {
    background: none;
    border: none;
    padding: 0;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.chat-block h2 {
  color: #fff;
  font-weight: 400;
  font-size: 22px;
  line-height: 34px;
  margin-top: 0;
}
