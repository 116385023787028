.left-side {
  display: flex;
  flex-direction: column;
  background: rgba(103, 179, 226, 0.4);
  width: 440px;
  height: 100%;
  padding: 45px 40px 30px;

  .site-btn {
    margin-top: auto;
  }

  .menu-link {
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    text-decoration: none;

    span {
      color: #fff;
      font-size: 12px;
      line-height: 14px;

      &:first-child {
        font-size: 18px;
        line-height: 21px;
      }
    }
  }
}

.user-anamnez-block {
  height: calc(100% - 94px);

  .scroller {
    height: 100%;
  }
  .ScrollbarsCustom-Content {
    height: 100%;
  }
  .anamnez-block-inner {
    flex: 1 0 100%;
    height: 100%;
  }

  .ScrollbarsCustom-Wrapper {
    right: 46px !important;
  }
  .ScrollbarsCustom-TrackY {
    background: #fff !important;
    border-radius: 5px !important;
    top: 0 !important;
    height: 100% !important;
  }
  .ScrollbarsCustom-ThumbY {
    background: #fbc437 !important;
    border-radius: 5px !important;
  }
}
