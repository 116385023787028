.index-block{
  padding: 0 60px;

  h2{
    font-weight: 400;
    font-size: 29px;
    line-height: 34px;
    text-align: center;
    color: #FFFFFF;
  }
}
.alert{padding: 1rem;}
.alert.alert-error{color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;;}

.search-block{
  background: #67B3E2;
  border-radius: 5px;
  display: flex;
  height: 200px;
  align-items: center;

  form{
    display: flex; 
    width: 100%;
    padding: 0 58px;

    .MuiAutocomplete-root{
      flex: 1; margin-right: 18px;

      .MuiFilledInput-root {
        background: #FFFFFF;
        box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        height: 64px;
        padding-top: 0;

        &::before{border-bottom-color: transparent;}
      }
    }
    .form-control-row{}
  }
}